import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import {
    AppShellConfiguration,
    AppShellModule,
    AppShellUtilitiesModule,
    PrimaryNavigationService,
} from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaAuthSignoutModule } from '@priva/auth/signout';
import { PrivaButtonModule } from '@priva/components/button';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaStatusGlobalModule } from '@priva/components/status-global';
import {
    PrivaErrorPageHandlerService,
    PrivaErrorPagesConfig,
    PrivaErrorPagesModule,
} from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { environment } from '../environments/environment';
import { AppMainComponent } from './app-main.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigurationModule } from './common/configuration/app-configuration.module';
import { AppConfigurationService } from './common/configuration/app-configuration.service';
import { NavigationModule } from './common/routing/navigation.module';
import { AppPrimaryNavigationService } from './common/routing/primary-navigation.service';
import { GatewayFeatureStateModule } from './modules/partner/shared/gateway-state';
import { GeneralFeatureStateModule } from './modules/partner/shared/general-state';
import { PartnerFeatureStateModule } from './modules/partner/shared/partner-state';
import { LogDiagnoseFeatureStateModule } from './modules/support/shared/log-diagnose-state';
import { UserFeatureStateModule } from './shared/user-state';
import { AppEffects } from './state/app.effects';
import { appReducer } from './state/app.reducer';

export function getCustomAuthOptions(): PrivaAuthHttpOptions {
    return { bffConfig: { csrfValue: undefined } };
}

export function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration as any);
}

export function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    const options = new PrivaLocalizationOptions();
    options.path = 'assets/translations';
    options.version = environment.version;
    options.availableLanguagesResource = 'assets/translations/locales.json';
    options.defaultLanguage = 'en-US';
    return options;
}

export function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

/**
 * Error Pages Configuration
 */
export function getErrorPagesConfig(configService: AppConfigurationService): PrivaErrorPagesConfig {
    return new PrivaErrorPagesConfig({
        uris: { portalUri: configService.configuration.components.portalShortcut.pageUri },
    });
}

/**
 * Preparation for State Management
 */
export const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: true,
        strictActionSerializability: true,
    },
};

/* istanbul ignore next lvb: depends on environment */
export const devImports = [
    StoreDevtoolsModule.instrument({
        maxAge: 250,
        logOnly: false,
    }),
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer[] = !environment.production && environment.debugState ? [debug] : [];

@NgModule({
    declarations: [AppMainComponent, AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,

        HttpClientModule,

        PrivaAuthCurrentUserBffModule.forRoot(),
        PrivaAuthHttpBffModule.forRoot({
            provide: PrivaAuthHttpOptions,
            useFactory: getCustomAuthOptions,
            deps: [AppConfigurationService],
        }),

        AppRoutingModule,

        NavigationModule.forRoot(),

        AppConfigurationModule.forRoot(),

        PrivaWindowEventsModule.forRoot(),
        PrivaScrollbarModule.forRoot(),
        PrivaDynamicModule,
        PrivaStatusGlobalModule,
        PrivaButtonModule,

        AppShellModule.forRoot(
            {
                provide: AppShellConfiguration,
                useFactory: getAppShellConfig,
                deps: [AppConfigurationService],
            },
            [
                AppShellModule.provideDefaultFeatureToggleRouter(),
                { provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService },
            ],
        ),

        AppShellUtilitiesModule,

        PrivaToggleModule.forRoot(),
        PrivaNotificationsModule.forRoot(),
        PrivaScrollToModule.forRoot(),
        PrivaAuthSignoutModule,

        /**
         * Localization, Storage & Translate configuration
         */
        PrivaLocalizationModule.forRoot({
            provide: PrivaLocalizationOptions,
            useFactory: getCustomLocalizationOptions,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: getVersionBasedTranslationLoader,
                deps: [HttpClient, PrivaLocalizationOptions],
            },
        }),

        PrivaErrorPagesModule.forRoot({
            provide: PrivaErrorPagesConfig,
            useFactory: getErrorPagesConfig,
            deps: [AppConfigurationService],
        }),

        /**
         * StateManagement, NgRx Store & Effects
         */
        UserFeatureStateModule,
        PartnerFeatureStateModule,
        GatewayFeatureStateModule,
        LogDiagnoseFeatureStateModule,
        GeneralFeatureStateModule,

        StoreModule.forRoot(
            {
                app: appReducer,
                router: routerReducer,
            },
            { metaReducers, ...STORE_CONFIG },
        ),
        EffectsModule.forRoot([AppEffects]),

        StoreRouterConnectingModule.forRoot(),

        ...devImports,
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        PrivaErrorPageHandlerService,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    json: () => import('highlight.js/lib/languages/json'),
                    text: () => import('highlight.js/lib/languages/plaintext'),
                },
                themePath: 'assets/styles/highlightjs/github.css',
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
