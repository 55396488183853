import { Action, createReducer, on } from '@ngrx/store';

import { LogDiagnoseAction } from '.';
import { initialLogDiagnoseState, LogDiagnoseState } from './log-diagnose.state';

const reducer = createReducer<LogDiagnoseState>(
    initialLogDiagnoseState,

    on(LogDiagnoseAction.readLogDiagnoseItems, (state) => {
        return {
            ...state,
            isLoading: true,
            hasLoadingFailed: false,
        };
    }),
    on(LogDiagnoseAction.readLogDiagnoseItemsSuccess, (state, action) => {
        return {
            ...state,
            logDiagnoseItems: action.logDiagnoseItems,
            isLoading: false,
            hasLoadingFailed: false,
        };
    }),
    on(LogDiagnoseAction.readLogDiagnoseItemsFailed, (state) => {
        return {
            ...state,
            logDiagnoseItems: [],
            isLoading: false,
            hasLoadingFailed: true,
        };
    }),
    on(LogDiagnoseAction.createLogDiagnoseItemSuccess, (state, action) => {
        const newLogDiagnoseItems = [...state.logDiagnoseItems, action.logDiagnoseItem];
        return {
            ...state,
            logDiagnoseItems: newLogDiagnoseItems,
        };
    }),
    on(LogDiagnoseAction.updateLogDiagnoseItemSuccess, (state, action) => {
        const updatedLogDiagnoseItems = state.logDiagnoseItems.map((item) => {
            if (item.moduleId === action.logDiagnoseItem.moduleId && item.id === action.logDiagnoseItem.id) {
                return action.logDiagnoseItem;
            } else {
                return item;
            }
        });
        return {
            ...state,
            logDiagnoseItems: updatedLogDiagnoseItems,
        };
    }),
    on(LogDiagnoseAction.deleteLogDiagnoseItemSuccess, (state, action) => {
        const itemToRemove = state.logDiagnoseItems.find(
            (item) =>
                item.moduleId == action.logDiagnoseItem.moduleId && item.id == action.logDiagnoseItem.id,
        );
        const updatedLogDiagnoseItems = state.logDiagnoseItems.filter((item) => item !== itemToRemove);
        return {
            ...state,
            logDiagnoseItems: updatedLogDiagnoseItems,
        };
    }),
);

export function logDiagnoseReducer(state: LogDiagnoseState | undefined, action: Action) {
    return reducer(state, action);
}
