import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GatewayModuleVersionDto } from 'app/modules/partner/shared/general-state';

import { WebSupportSubService } from '../web-support/web-support-sub.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerGeneralService extends WebSupportSubService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getModuleVersions(): Observable<GatewayModuleVersionDto[]> {
        const url = `${this.getApiUrl()}/partner/general/moduleversions`;

        return this.http
            .get<GatewayModuleVersionDto[]>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }
}
