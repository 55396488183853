import { Action, createReducer, on } from '@ngrx/store';

import { GeneralAction } from '.';
import { GeneralState, initialGeneralState } from './general.state';

const reducer = createReducer<GeneralState>(
    initialGeneralState,

    on(GeneralAction.readModuleVersions, (state) => {
        return {
            ...state,
            isLoadingModuleVersions: true,
        };
    }),
    on(GeneralAction.readModuleVersionsSuccess, (state, action) => {
        return {
            ...state,
            moduleVersions: action.moduleVersions,
            isLoadingModuleVersions: false,
        };
    }),
    on(GeneralAction.readModuleVersionsFailed, (state) => {
        return {
            ...state,
            moduleVersions: [],
            isLoadingModuleVersions: false,
        };
    }),
);

export function generalReducer(state: GeneralState | undefined, action: Action) {
    return reducer(state, action);
}
