import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { PartnerWebSupportService } from 'app/shared/services/partner';

import { GeneralAction } from './';

@Injectable({
    providedIn: 'root',
})
export class GeneralEffects {
    constructor(
        private readonly actions$: Actions,
        private webSupportService: PartnerWebSupportService,
    ) {}

    public readModuleVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GeneralAction.readModuleVersions),
            switchMap((_action) => {
                return this.webSupportService.general.getModuleVersions().pipe(
                    map((moduleVersions) => {
                        return GeneralAction.readModuleVersionsSuccess({ moduleVersions: moduleVersions });
                    }),
                    catchError((error: Error) => {
                        return of(GeneralAction.readModuleVersionsFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });
}
