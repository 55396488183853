import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureName } from 'app/state';

import { GeneralEffects, generalReducer } from './state';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(FeatureName.General, generalReducer),
        EffectsModule.forFeature([GeneralEffects]),
    ],
    exports: [],
})
export class GeneralFeatureStateModule {}
