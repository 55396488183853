import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureName } from 'app/state';

import { GeneralState } from './general.state';

const getGeneralFeatureState = createFeatureSelector<GeneralState>(FeatureName.General);

export const getModuleVersions = createSelector(
    getGeneralFeatureState,
    (state: GeneralState) => state.moduleVersions,
);
