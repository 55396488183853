import { createAction, props } from '@ngrx/store';

import {
    MetadataUploadInfoDto,
    PartnerRestartModuleResult,
    ProjectTopology,
} from 'app/shared/services/partner';
import { ProjectType } from 'app/shared/services/partner/enumerations';
import { GatewayDiagnoseResult } from 'app/shared/services/partner/interfaces/gateway-diagnose-result.interface';
import { EdgeGatewayDetailsDto, GatewayDetailsDto } from 'app/shared/services/web-support/gateway.model';

import { SystemInfrastructureModulesView } from '../enumerations';
import { InfrastructureModulesView } from '../interfaces';

export const readGatewayDetails = createAction(
    '[Gateway] Read the gateway details',
    props<{ assetId: string }>(),
);
export const readGatewayDetailsSuccess = createAction(
    '[Gateway] Read the gateway details success',
    props<{ gatewayDetails: GatewayDetailsDto }>(),
);
export const readGatewayDetailsFailed = createAction(
    '[Gateway] Read the gateway details failed',
    props<{ loadError: Error }>(),
);

export const readEdgeGatewayDetails = createAction('[Gateway] Read the Edge gateway details');
export const readEdgeGatewayDetailsSuccess = createAction(
    '[Gateway] Read the Edge gateway details success',
    props<{ edgeGatewayDetails: EdgeGatewayDetailsDto }>(),
);
export const readEdgeGatewayDetailsFailed = createAction(
    '[Gateway] Read the Edge gateway details failed',
    props<{ loadError: Error }>(),
);

export const pingGateway = createAction('[Gateway] Ping the current selected gateway');
export const pingGatewaySuccess = createAction(
    '[Gateway] Ping the current selected gateway success',
    props<{ resultCode: number }>(),
);
export const pingGatewayFailed = createAction('[Gateway] Ping the current selected gateway failed');

export const startDiagnose = createAction('[Gateway] Start diagnose the current selected gateway');
export const startDiagnoseSuccess = createAction('[Gateway] Start diagnose the selected gateway success');
export const startDiagnoseFailed = createAction('[Gateway] Start diagnose the selected gateway failed');

export const stopDiagnose = createAction('[Gateway] Stop diagnose the current selected gateway');

export const diagnoseGatewayUpdate = createAction(
    '[Gateway] Update the diagnose state the selected gateway success',
    props<{ diagnoseResult: GatewayDiagnoseResult }>(),
);

export const initializeInfrastructureModuleViews = createAction(
    '[Gateway] Initialize infrastructure module views',
    props<{ views: InfrastructureModulesView[] }>(),
);
export const selectInfrastructureModuleView = createAction(
    '[Gateway] Select infrastructure module view',
    props<{ selectedViewId: SystemInfrastructureModulesView | undefined }>(),
);

export const getProjectTopology = createAction(
    '[Gateway] Get project topology of the current selected gateway',
);
export const getProjectTopologySuccess = createAction(
    '[Gateway] Get project topology of the selected gateway success',
    props<{ projectTopology: ProjectTopology }>(),
);
export const getProjectTopologyFailed = createAction(
    '[Gateway] Get project topology of the selected gateway failed',
);

export const getProjectType = createAction('[Gateway] Get project type of the current selected gateway');
export const getProjectTypeSuccess = createAction(
    '[Gateway] Get project type of the selected gateway success',
    props<{ projectType: ProjectType }>(),
);
export const getProjectTypeFailed = createAction('[Gateway] Get project type of the selected gateway failed');

export const restartModule = createAction(
    '[Gateway] Restart module on the selected gateway',
    props<{ moduleId: string }>(),
);
export const restartModuleSuccess = createAction(
    '[Gateway] Restart module on the selected gateway success',
    props<{ restartResult: PartnerRestartModuleResult }>(),
);
export const restartModuleFailed = createAction(
    '[Gateway] Restart module on the selected gateway failed',
    props<{ restartResult: PartnerRestartModuleResult }>(),
);
export const selectDiagnoseResult = createAction(
    '[Gateway] Select diagnose result of specific module',
    props<{ dialogResult: GatewayDiagnoseResult | undefined }>(),
);

export const getMetadataUploadInfo = createAction(
    '[Gateway] Get get metadata upload info of the current selected gateway',
);
export const getMetadataUploadInfoSuccess = createAction(
    '[Gateway] Get metadata upload info of the selected gateway success',
    props<{ metadataUploadInfo: MetadataUploadInfoDto }>(),
);
export const getMetadataUploadInfoFailed = createAction(
    '[Gateway] Get metadata upload info of the selected gateway failed',
);
