import { createAction, props } from '@ngrx/store';

import { GatewayModuleVersionDto } from '../interfaces';

export const readModuleVersions = createAction('[General] Read the module versions');
export const readModuleVersionsSuccess = createAction(
    '[General] Read the module versions success',
    props<{ moduleVersions: GatewayModuleVersionDto[] }>(),
);
export const readModuleVersionsFailed = createAction(
    '[General] Read the module versions failed',
    props<{ loadError: Error }>(),
);
