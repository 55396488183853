import * as appState from 'app/state/app.state';

import { GatewayModuleVersionDto } from '../interfaces';

export interface AppState extends appState.AppState {
    GeneralState: GeneralState;
}

export interface GeneralState {
    moduleVersions: GatewayModuleVersionDto[];
    isLoadingModuleVersions: boolean;
}

export const initialGeneralState: GeneralState = {
    moduleVersions: [],
    isLoadingModuleVersions: false,
};
