import { Injectable } from '@angular/core';

import { PartnerAssetService } from './partner-asset.service';
import { PartnerGatewayDiagnoseService } from './partner-gateway-diagnose.service';
import { PartnerGatewayService } from './partner-gateway.service';
import { PartnerGeneralService } from './partner-general.service';
import { PartnerTenantService } from './partner-tenant.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerWebSupportService {
    constructor(
        private assetService: PartnerAssetService,
        private gatewayService: PartnerGatewayService,
        private tenantService: PartnerTenantService,
        private gatewayDiagnoseService: PartnerGatewayDiagnoseService,
        private partnerGeneralService: PartnerGeneralService,
    ) {}

    get asset(): PartnerAssetService {
        return this.assetService;
    }

    get gateway(): PartnerGatewayService {
        return this.gatewayService;
    }

    get tenant(): PartnerTenantService {
        return this.tenantService;
    }

    get gatewayDiagnose(): PartnerGatewayDiagnoseService {
        return this.gatewayDiagnoseService;
    }

    get general(): PartnerGeneralService {
        return this.partnerGeneralService;
    }
}
